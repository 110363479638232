<template>
  <div>
    <!-- 活动周标题 -->
    <top :islist="islist"></top>
    <!-- 模块标题 -->
    <tech-title
      :category1="category1"
      :category2="category2"
      :subtitle="subtitle"
      :iscloud="iscloud"
    ></tech-title>
    <!-- 新闻 -->
    <div class="content-wrapper">
      <h4>{{title}}</h4>
      <p class="time"><span>作者：{{author}}</span><span class="line">|</span><span>单位：{{unit}}</span></p>
      <div class="content" v-html="content"></div>
    </div>
    <page-footer />
  </div>
</template>

<script>
import Top from '@/views/mobile/components/Top.vue';
import TechTitle from './components/TechTitle.vue';
import PageFooter from './components/PageFooter.vue';
import { getWonderfulElements } from '@/api/mobile.js'
import { appTitle } from '@/utils/get-page-title.js'
export default {
  name: 'MExhibitsDetail',
  components: {
    Top,
    TechTitle,
    PageFooter
  },
  data() {
    return {
      category1: '精彩',
      category2: '展项',
      subtitle: 'Wonderful exhibition items',
      islist: false,
      content: '',
      id:this.$route.params.id,
      iscloud:false,
      title:'',
      author:'',
      unit:''
    };
  },
  created(){
    this.getDetail()
  },
  methods:{
    getDetail(){
      getWonderfulElements({id:this.id}).then(res => {
        let {author,theme,unit,exhibitionProfile} = res.data
        this.author = author
        this.title = theme
        this.unit = unit
        document.title = theme + '-' + appTitle
        this.content = exhibitionProfile
      })
    }
  }
};
</script>

<style scoped lang="scss">
.content-wrapper{
  padding:40px;
}
h4 {
  font-size: 36px;
  color: #333;
  text-align: center;
}
.time {
  font-size: 24px;
  line-height: 24px;
  color: #999;
  text-align: center;
  margin-top:20px;
}
.time .line{
  margin:0px 10px;
}
.content{
  margin-top: 30px;
}
::v-deep .content p{
  line-height: 48px;
  font-size: 30px;
}
::v-deep .content img{
  max-width: 100%;
  height: auto;
}
::v-deep .content iframe,::v-deep .content video{
  max-width: 100%;
  max-height: 400px;
}
</style>
